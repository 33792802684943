import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>FEDEZZE FEL különböző termékeink előnyeit</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/termekeink/',
    label: 'AZ ÖSSZES TERMÉK MEGTEKINTÉSE',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>Erősen szennyezett töltettel</li>\n<li><sup>**</sup>Összehasonlítva a Finish Power termékkel átlagos súly alapján</li>\n<li><sup>***</sup>Megbírkózik a ráégett ételmaradékokkal</li>\n<li><sup>****</sup>Összetevő: Enzim/li>\n</ul>',
      rows: [
        {
          text: 'Tisztaság',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8faab67589970a3f/678ed1226a6f31aa5483a443/clean.svg',
        },
        {
          text: 'Csillogás',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c375d603a782ef/678ed12214bc5299f68b2870/shine.svg',
        },
        {
          text: '48 órás rászáradt ételmaradékok*',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltca09100a97568352/678ed122c06ae4c71310b916/dried-on.svg',
        },
        {
          text: 'Megbírkózik a zsírral',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt65436798b3fee8ab/678ed1223c3aa3697966a28b/tough-on-grease.svg',
        },
        {
          text: 'Gyorsan oldódó',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd5960adff862c28b/678ed1222b1a0e3fc03cf8aa/fast-dissolving.svg',
        },
        {
          text: 'Kevesebb vegyi anyag**',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltea4bd36a225e5178/678ed122a0c960a7748f2de8/less-chemical-weight.svg',
        },
        {
          text: 'Üveg védelem',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7cab588634b3a208/678ed1223c3aa3b1b666a28f/glass-protection.svg',
        },
        {
          text: 'Friss illat',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt229c48ee678970ec/678ed122d3bf2e7bfcfd869b/freshness-boost.svg',
        },
        {
          text: 'Ráégett ételmaradékok***',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt12626c84d8a7ff49/678ed1227121be572f56f690/burnt-on-stains.svg',
        },
        {
          text: 'Megfelelő idő, megfelelő összetevő****',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2f8bf8b97d913f5b/678ed122c06ae4c5cf10b91a/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc6043804b492362f/678ed3b1295de09955bfe918/ultimate-plus-diff-group-eu.webp',
          productName: 'ULTIMATE PLUS',
          productLink: '/termekeink/kapszulak/ultimate-plus/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltbbf0342226616acb/678ed3b100a21a79cf651d38/ultimate-diff-group-eu.webp',
          productName: 'ULTIMATE',
          productLink: '/termekeink/kapszulak/ultimate/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltfffb3e8845ce053d/678ed3b1101c364cee913af5/quantum-diff-group-eu.webp',
          productName: 'QUANTUM',
          productLink: '/termekeink/kapszulak/quantum/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt119dc324d6cdc26f/678ed3b186a1fd4672d28cf1/power-plus-diff-group-eu.webp',
          productName: 'POWER',
          productLink: '/termekeink/tablettak/power/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              productImage:
                'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt51e831cfe42cecfb/678ed3b1812d2c7e778cf23e/power-essential-86-diff-group-eu.web',
              productName: 'POWER ESSENTIAL',
              productLink: '/termekeink/tablettak/power-essentials/',
              color: 'sky-blue-gradient',
              values: [
                {
                  value: 1,
                },
                {
                  value: 1,
                },
                {
                  value: 1,
                },
                {
                  value: 1,
                },
              ],
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt51e831cfe42cecfb/678ed3b1812d2c7e778cf23e/power-essential-86-diff-group-eu.webp',
          productName: 'POWER ESSENTIAL',
          productLink: '/termekeink/tablettak/power-essentials/',
          color: 'sky-blue-gradient',
          values: [
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
    {
      title: 'FINISH 0%',
      legend:
        '<ul>\n<li><sup>*</sup>Összehasonlítva a Finish Power termékkel átlagos súly alapján</li>\n</ul>',
      rows: [
        {
          text: 'Öko minősítésű',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd76a4ab241e2304e/678ed75ca116968653bf96de/svgviewer-png-output_(1).png',
        },
        {
          text: 'Tisztaság',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8faab67589970a3f/678ed1226a6f31aa5483a443/clean.svg',
        },
        {
          text: 'Csillogás',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c375d603a782ef/678ed12214bc5299f68b2870/shine.svg',
        },
        {
          text: 'Megbírkózik a zsírral',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt65436798b3fee8ab/678ed1223c3aa3697966a28b/tough-on-grease.svg',
        },
        {
          text: 'Ne öblítse el az edényeit',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt31f26b8c927a5e2b/678ed7fe6706a17a6fcd48c8/skip-the-rinse.svg',
        },
        {
          text: 'Gyorsan oldódó',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd5960adff862c28b/678ed1222b1a0e3fc03cf8aa/fast-dissolving.svg',
        },
        {
          text: 'Kevesebb vegyi anyag*',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltea4bd36a225e5178/678ed122a0c960a7748f2de8/less-chemical-weight.svg',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte57848e537cc584a/678ed874420e0f58c0e17589/ultimate-0-diff-group-eu.webp',
          productName: 'ULTIMATE ALL IN 1 0%',
          productLink: '/termekeink/kapszulak/ultimate-0/45-regular/',
          color: 'light-green-gradient',
          values: [
            {
              image:
                'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt5de57e261d5f98f3/678ed8c1bee1952e864a1e3a/svgviewer-png-output_(2).png',
            },
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltaea5907e5cc4e457/678eda9c6706a12d6bcd48d5/ult60-hu.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/termekeink/kapszulak/ultimate/',
        text: 'FEDEZZE FEL AZ ULTIMATE KAPSZULÁT!',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt972cca7cbb3bc913/678ed9ff7121be6b1156f69b/1_(1).webp',
          name: 'MEGBÍRKÓZIK A ZSÍRRAL',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc1cdcaad312aff25/678ed9ff6706a18d69cd48cd/2_(2).webp',
          name: 'ELTÁVOLÍTJA A<strong>48H</strong><br/>ÓRÁS RÁÉGETT MARADÉKOKAT',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blted03135b119d8a50/678eda2545db93edfc6326a6/ultimate-plus-54-hu.webp',
      cardStyle: 'black',
      button: {
        link: '/termekeink/kapszulak/ultimate-plus/',
        text: 'Fedezze fel a valaha volt legjobb teljesítményű kapszulánkat, az ULTIMATE PLUS-t!',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd46c89a22bc95e13/678ed9ff00a21aa473651d40/1-gold.webp',
          name: '<strong>3X</strong>tisztító erő',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7b9c7a19a05866d8/678ed9ff295de08674bfe91d/gold-2.webp',
          name: 'Megbírkózik a zsírral',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt97febc17f42ab495/678ed9ffa15dc194feaab375/gold-3.webp',
          name: 'ELTÁVOLÍTJA A RÁÉGETT MARADÉKOKAT',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt151576478f47b3cd/678eda385b42b061da6e9061/quantum-60-hu.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/termekeink/kapszulak/quantum/',
        text: 'FEDEZZE FEL A QUANTUM KAPSZULÁT!',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt972cca7cbb3bc913/678ed9ff7121be6b1156f69b/1_(1).webp',
          name: 'Megbírkózik a zsírral',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  description:
    'Melden Sie sich über das folgende Formular für den Finish Newsletter an und bleiben Sie immer auf dem neuesten Stand rund um Finish! Mit betätigen des ANMELDEN-Buttons willigen Sie ein, von RB Hygiene Home Deutschland GmbH Informationen zu aktuellen Angeboten, Aktionen und anderen Neuigkeiten zur Finish Marke per E-Mail zu erhalten.',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
